import React, { useState, useEffect, useRef } from 'react'
import { HiOutlineExternalLink } from "react-icons/hi"
import { useSelector, useDispatch } from '../../services/hooks/reduxHooks'
import { closeInfo } from '../../services/redux/slices/linkInfoSlice'
import { openEditModal } from '../../services/redux/slices/linkEditSlice'
import { deleteLink } from '../../services/api/api'

import styles from './LinkInfo.module.css'
import InfoHeader from './info-header/info-header'

const LinkInfo: React.FC = () => {
  const dispatch = useDispatch()
  const { link } = useSelector(store => store.linkInfo)
  const [ linkForCopy, setLinkForCopy ] = useState<string>(`https://skidki.promo/${link.from}`)
  const trueLink = `https://skidki.promo/${link.from}`

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    setLinkForCopy(`https://skidki.promo/${link?.from}`)
  }, [link])

  useEffect(() => {
    if (textAreaRef.current !== null) textAreaRef.current.value = trueLink
  }, [link, textAreaRef, trueLink])

  const copyLink = (): void => {
    if (textAreaRef.current !== null)
    textAreaRef.current.select()
    document.execCommand("copy")
    setLinkForCopy('Скопированно')
    setTimeout((): void => {
      setLinkForCopy(`https://skidki.promo/${link?.from}`)
    }, 1100)
  }

  const deleteLinkHandler = (): void => {
    deleteLink(dispatch, link.id)
    dispatch(closeInfo())
  }

  const editLink = (): void => {
    dispatch(openEditModal())
  }

  return (
    <div className={styles.box}>
      <div className={styles.box__fixed}>
        <InfoHeader name={link.name} date={link.createdAt}/>
        <div className={styles.hr__line} />
        <div className={styles.clicks}>
          <div className={styles.click}>
            <div className={styles.click__number}>{link.click}</div>
            <div className={styles.click__subtitle}>Сегодня</div>
          </div>
          <div className={styles.click}>
            <div className={styles.click__number}>{link.click}</div>
            <div className={styles.click__subtitle}>За все время</div>
          </div>
        </div>
        <div className={styles.link__box} onClick={copyLink} tabIndex={0} onKeyDown={copyLink}>
          {linkForCopy}
          <textarea ref={textAreaRef} defaultValue={trueLink} className={styles.textarea} tabIndex={-1}/>
          <div className={styles.link__icon}><HiOutlineExternalLink /></div>
        </div>
        <div className={styles.destination}>
          <div className={styles.destination__title}>
            Оригинальная ссылка:
          </div>
          <a href={link.to} target='_blank' rel="noreferrer">
            <p className={styles.destination__text}>
              {link.to}
            </p>
          </a>
        </div>
        <div className={styles.two__buttons}>
          <button className={styles.button} onClick={editLink}>Редактировать</button>
          <button className={`${styles.button} ${styles.delete}`} onClick={deleteLinkHandler}>Удалить</button>
        </div>
      </div>
    </div>
  )
}

export default LinkInfo