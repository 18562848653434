import axios from "axios"
import { userLoginRequest, userLoginError, addUser, authCheckFailed, authCheckSuccess } from "../redux/slices/userSlice"
import {
  linksRequest,
  linksRequestError,
  linksRequestSuccess,
  linkCreateRequest,
  linkCreateSuccess,
  linkCreateFailed,
  linkDeleteError,
  linkDeleteRequest,
  linkDeleteSuccess
} from "../redux/slices/linksSlice"
import { closeEditModal, editError, editRequest, editSuccess } from "../redux/slices/linkEditSlice"
import { updateInfo } from "../redux/slices/linkInfoSlice"

import { AppDispatch } from "../redux"
import { IInput } from "../../components/LinkCreator/ILinkCreator"

const URL: string = 'https://link.skidki.promo/'

export const userLogin = async (dispatch: AppDispatch, username: string, password: string): Promise<void> => {
  dispatch(userLoginRequest())
  try {
    const res: any = await axios.post(`${URL}auth/login`,{
      username: username,
      password: password
    })
    if (res.data.success) {
      localStorage.setItem('token', res.data.token)
      dispatch(addUser(res.data.user))
    } else throw new Error('Ошибка')
  } catch (err) {
    dispatch(userLoginError())
    // Ошибка логина
  }
}

export const checkAuth = async (dispatch: AppDispatch): Promise<void> => {
  try {
    const token: string | null = localStorage.getItem('token')
    if (!token) throw new Error('Ошибка')
    const res = await axios.get(`${URL}auth/getuser`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    dispatch(authCheckSuccess(res.data.user))
  } catch (err) {
    localStorage.clear()
    dispatch(authCheckFailed())
  }
}

export const createLink = async (dispatch: AppDispatch, inputValue: IInput): Promise<void> => {
  try {
    dispatch(linkCreateRequest())
    const token: string | null = localStorage.getItem('token')
    if (!token) throw new Error('Ошибка')
    await axios.post(`${URL}link/create`,{
      name: inputValue.name,
      from: inputValue.url,
      to: inputValue.link,
      isFavorite: inputValue.favorite
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    dispatch(linkCreateSuccess())
    getAllLinks(dispatch)
  } catch (err) {
    dispatch(linkCreateFailed())
    console.log(err)
  }
}

export const getAllLinks = async (dispatch: AppDispatch): Promise<void> => {
  try {
    dispatch(linksRequest())
    const token: string | null = localStorage.getItem('token')
    if (!token) throw new Error('Ошибка')
    const res = await axios.get(`${URL}link/all`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    dispatch(linksRequestSuccess(res.data.data))
  } catch (err) {
    dispatch(linksRequestError())
    console.log(err)
  }
}

export const deleteLink = async (dispatch: AppDispatch, id: string): Promise<void> => {
  try {
    dispatch(linkDeleteRequest())
    const token: string | null = localStorage.getItem('token')
    if (!token) throw new Error('Ошибка')
    await axios.delete(`${URL}link/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    dispatch(linkDeleteSuccess())
    getAllLinks(dispatch)
  } catch (err) {
    dispatch(linkDeleteError())
    console.log(err)
  }
}

interface body {
  name: string,
  to: string,
  from: string
}

export const fetchEditLink = async (dispatch: AppDispatch, id: string, body: body): Promise<void> => {
  try {
    const token: string | null = localStorage.getItem('token')
    if (!token) throw new Error('Ошибка')
    dispatch(editRequest())
    await axios.patch(`${URL}link/${id}`, {
      name: body.name,
      to: body.to,
      from: body.from
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    dispatch(closeEditModal())
    dispatch(updateInfo({
      name: body.name,
      to: body.to,
      from: body.from
    }))
    dispatch(editSuccess())
    getAllLinks(dispatch)
  } catch (err) {
    dispatch(editError())
  }
}