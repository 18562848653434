import React, { useState, useEffect } from 'react'
import styles from './LoginPage.module.css'
import { userLogin } from '../../services/api/api'
import { useDispatch, useSelector } from '../../services/hooks/reduxHooks'
import ButtonLoader from '../ButtonLoader/ButtonLoader'
import Logo from '../../assets/png/logo.png'

const LoginPage: React.FC = () => {
  const { loading, error } = useSelector(store => store.user)
  const dispatch = useDispatch()
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const [inputDisabled, setInputDisabled] = useState<boolean>(false)
  const [login, setLogin] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  useEffect(() => {
    if (login.length > 2 && password.length > 2) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
  }, [login, password])

  useEffect(() => {
    loading ? setInputDisabled(true) : setInputDisabled(false)
    if (loading) setBtnDisabled(true)
    if (!loading && error) setBtnDisabled(false)
  }, [loading, error])

  const onChangeLogin = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLogin(e.target.value)
  }
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value)
  }

  const makeLogin = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    userLogin(dispatch, login, password)
  }

  return (
    <div className={styles.wrapper}>
      <form className={styles.form}>
        <div className={styles.logo}>
          <img src={Logo} alt="" width='100px' />
        </div>
        {error && <div className={styles.error}>Данные введены неверно</div>}
        <input placeholder='Логин' value={login} className={styles.input} onChange={onChangeLogin} disabled={inputDisabled}/>
        <input placeholder='Пароль' value={password}  type="password" className={styles.input} onChange={onChangePassword} disabled={inputDisabled}/>
        <button disabled={btnDisabled} className={styles.btn} onClick={makeLogin}>
          {loading ? <ButtonLoader /> : 'Войти'}
        </button>
      </form>
    </div>
  )
}

export default LoginPage