import { createSlice } from "@reduxjs/toolkit"
import { ILink } from '../../../types'

interface IInitialState {
  links: Array<ILink>,
  loading: boolean,
  error: boolean,
  createLoading: boolean,
  createError: boolean,
  createSuccess: boolean,
  deleteLoading: boolean,
  deleteSuccess: boolean,
  deleteError: boolean
}

const initialState: IInitialState = {
  links: [],
  loading: false,
  error: false,
  createLoading: false,
  createError: false,
  createSuccess: false,
  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false
}

const linksSlice = createSlice({
  name: 'links',
  initialState,
  reducers: {
    linksRequest(state: IInitialState) {
      state.loading = true
      state.error = false
    },
    linksRequestError(state: IInitialState) {
      state.loading = false
      state.error = true
    },
    linksRequestSuccess(state: IInitialState, action: any) {
      state.loading = false
      state.error = false
      state.links = action.payload
    },
    linkCreateRequest(state: IInitialState) {
      state.createLoading = true
      state.createError = false
      state.createSuccess = false
    },
    linkCreateSuccess(state: IInitialState) {
      state.createLoading = false
      state.createError = false
      state.createSuccess = true
    },
    linkCreateFailed(state: IInitialState) {
      state.createLoading = false
      state.createError = true
      state.createSuccess = false
    },
    linkDeleteRequest(state: IInitialState) {
      state.deleteLoading = true
      state.deleteError = false
      state.deleteSuccess = false
    },
    linkDeleteSuccess(state: IInitialState) {
      state.deleteLoading = false
      state.deleteError = false
      state.deleteSuccess = true
    },
    linkDeleteError(state: IInitialState) {
      state.deleteLoading = false
      state.deleteError = true
      state.deleteSuccess = false
    },
    sortByNameUp(state: IInitialState) {
      state.links = [...state.links].sort((a: ILink, b: ILink): number => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        return 0
      })
    },
    sortByNameDown(state: IInitialState) {
      state.links = [...state.links].sort((a: ILink, b: ILink): number => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return -1
        return 0
      })
    },
    sortByClickDown(state: IInitialState) {
      state.links = [...state.links].sort((a: ILink, b: ILink): number => {
        return a.click - b.click
      })
    },
    sortByClickUp(state: IInitialState) {
      state.links = [...state.links].sort((a: ILink, b: ILink): number => {
        return b.click - a.click
      })
    },
    sortByDateDown(state: IInitialState) {
      state.links = [...state.links].sort((a: ILink, b: ILink): number => {
        const dateA: any = new Date(a.createdAt as any)
        const dateB: any = new Date(b.createdAt as any)
        return dateA - dateB
      })
    },
    sortByDateUp(state: IInitialState) {
      state.links = [...state.links].sort((a: ILink, b: ILink): number => {
        const dateA: any = new Date(a.createdAt as any)
        const dateB: any = new Date(b.createdAt as any)
        return dateB - dateA
      })
    }
  }
})

export const {
  linksRequest, 
  linksRequestError,
  linksRequestSuccess,
  linkCreateRequest,
  linkCreateSuccess,
  linkCreateFailed, 
  linkDeleteError,
  linkDeleteRequest,
  linkDeleteSuccess,
  sortByNameUp,
  sortByNameDown,
  sortByClickDown,
  sortByClickUp,
  sortByDateDown,
  sortByDateUp
} = linksSlice.actions
export default linksSlice.reducer