import { createSlice } from "@reduxjs/toolkit"

interface IInitialState {
  isEditModalOpen: boolean,
  loading: boolean,
  error: boolean,
  success: boolean
}

const initialState: IInitialState = {
  isEditModalOpen: false,
  loading: false,
  error: false,
  success: false
}

const linkEditSlice = createSlice({
  name: 'edit',
  initialState,
  reducers: {
    openEditModal(state: IInitialState) {
      state.isEditModalOpen = true
    },
    closeEditModal(state: IInitialState) {
      state.isEditModalOpen = false
    },
    editRequest(state: IInitialState) {
      state.loading = true
      state.error = false
      state.success = false
    },
    editError(state: IInitialState) {
      state.loading = false
      state.error = true
      state.success = false
    },
    editSuccess(state: IInitialState) {
      state.loading = false
      state.error = false
      state.success = true
    }
  }
})

export const { openEditModal, closeEditModal, editSuccess, editRequest, editError } = linkEditSlice.actions
export default linkEditSlice.reducer