import { createSlice } from "@reduxjs/toolkit"
import { PayloadAction } from '@reduxjs/toolkit'
import { ILink } from '../../../types'

interface IInitialState {
  link: ILink,
  isOpen: boolean,
}

interface IActionUpdate {
  name: string,
  to: string,
  from: string
}

const initialState: IInitialState = {
  link: {
    id: '0',
    name: 'null',
    to: 'null',
    from: 'null',
    click: 0,
    isFavorite: false,
    createdAt: 'Вчера'
  },
  isOpen: false,
}

const linkInfoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setLink(state: IInitialState, action: PayloadAction<ILink>) {
      state.isOpen = true
      state.link = {...action.payload}
    },
    closeInfo(state: IInitialState) {
      state.isOpen = false
      state.link = {
        id: '0',
        name: 'null',
        to: 'null',
        from: 'null',
        click: 0,
        isFavorite: false,
        createdAt: 'Вчера'
      }
    },
    updateInfo(state: IInitialState, action: PayloadAction<IActionUpdate>) {
      state.link = {
        ...state.link,
        name: action.payload.name,
        from: action.payload.from,
        to: action.payload.to
      }
    }
  }
})

export const { setLink, closeInfo, updateInfo } = linkInfoSlice.actions
export default linkInfoSlice.reducer