import React from 'react'
import { useDispatch } from '../../../services/hooks/reduxHooks'
import { closeInfo } from '../../../services/redux/slices/linkInfoSlice'
import { getCorrectDate } from '../../../utils/get-correct-date'

import { IoCloseOutline } from "react-icons/io5"
import styles from './info-header.module.css'

interface IProps {
  name: string,
  date: string
}

const InfoHeader: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()

  const close = (): void => {
    dispatch(closeInfo())
  }
  
  return (
    <div className={styles.header}>
      <div>
        <div className={styles.name}>{props.name}</div>
        <div className={styles.date}>{getCorrectDate(props.date)}</div>
      </div>
      <div className={styles.icons}>
        <IoCloseOutline onClick={close}/>
      </div>
    </div>
  )
}

export default InfoHeader