import React, { useEffect, useRef } from 'react'
import { IoIosStats } from 'react-icons/io'
import { ILink } from '../../../types'
import { getCorrectDate } from '../../../utils/get-correct-date'
import { useDispatch, useSelector } from '../../../services/hooks/reduxHooks'
import { setLink, closeInfo } from '../../../services/redux/slices/linkInfoSlice'

import styles from './Link.module.css'

const Link: React.FC<ILink> = (props) => {
  const dispatch = useDispatch()
  const { link } = useSelector(store => store.linkInfo)
  const { id, name, click, from, createdAt } = props
  const ref = useRef<HTMLDivElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (ref.current) {
      if (link?.id === id) ref.current.style.backgroundColor = 'rgb(245, 245, 245)'
      else ref.current.style.backgroundColor = 'white'
    }
  })

  const openLinkInfo = (): void => {
    dispatch(closeInfo())
    dispatch(setLink(props))
  }

  const linkCopy = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    if (textAreaRef.current !== null)
    textAreaRef.current.select()
    document.execCommand("copy")
  }

  return (
    <div className={styles.box} ref={ref} onClick={openLinkInfo} tabIndex={0}>
      <div className={styles.name}>{name}</div>
      <div
        className={styles.link}
        onClick={linkCopy}
      >{`skidki.promo/${from}`}</div>
      {createdAt ? (
        <div className={styles.date}>{getCorrectDate(createdAt)}</div>
      ) : (
        <div className={styles.date}>Дата неизвестна</div>
      )}
      <div className={styles.stats}>
        {click}
        <div className={styles.icon}>
          <IoIosStats />
        </div>
      </div>
      <textarea
        className={styles.textarea}
        ref={textAreaRef}
        tabIndex={-1}
        readOnly
        value={`https://skidki.promo/${from}`}
      />
    </div>
  );
}

export default Link