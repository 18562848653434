import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from '../../services/hooks/reduxHooks'
import { getAllLinks } from '../../services/api/api'
import { sortByNameDown, sortByNameUp, sortByClickDown, sortByClickUp, sortByDateDown, sortByDateUp } from '../../services/redux/slices/linksSlice'
import Link from './Link/Link'
import Tab from '../Tab/Tab'

import styles from './LinkItems.module.css'

interface ILinkItems {
  tab: 'all' | 'favorite'
  change: any
}

const LinkItems: React.FC<ILinkItems> = (props) => {
  const [ nameToggle, setNameToggle ] = useState<boolean>(false)
  const [ clicksToggle, setClicksToggle ] = useState<boolean>(false)
  const [ dateToggle, setDateToggle ] = useState<boolean>(false)
  const { links } = useSelector(state => state.links)
  const dispatch = useDispatch()

  useEffect(() => {
    getAllLinks(dispatch)
  }, [dispatch])

  const sortByName = () => {
    nameToggle
      ? dispatch(sortByNameDown())
      : dispatch(sortByNameUp())
    setNameToggle(!nameToggle)
  }
  const sortByClicks = () => {
    clicksToggle
      ? dispatch(sortByClickDown())
      : dispatch(sortByClickUp())
    setClicksToggle(!clicksToggle)
  }
  const sortByDate = () => {
    dateToggle
      ? dispatch(sortByDateDown())
      : dispatch(sortByDateUp())
    setDateToggle(!dateToggle)
  }

  return (
    <div className={styles.box}>
      <div className={styles.heading}>
        <Tab change={props.change} active={props.tab} />
        <div className={styles.title}>
          <div className={styles.title__btn} onClick={sortByName}><p>Название</p></div>
          <div><p>Ссылка</p></div>
          <div className={styles.title__btn} onClick={sortByDate}><p>Дата</p></div>
          <div className={`${styles.title__btn} ${styles.title__click}`} onClick={sortByClicks}><p>Клики</p></div>
        </div>
      </div>
      <div className={styles.links__box}>
        {props.tab === 'all' && 
        links.map((link => <Link key={link.id} {...link}/>))
        }
        {props.tab === 'favorite' && 
          links.map((link => (
            link.isFavorite ?
            <Link key={link.id} {...link}/> : null
          )))
        }
      </div>
    </div>
  )
}

export default LinkItems