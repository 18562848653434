import React, { useState } from 'react'
import LinkItems from '../LinkItems/LinkItems'
import LinkInfo from '../LinkInfo/LinkInfo'
import Modal from '../Modal/Modal'
import { useSelector, useDispatch } from '../../services/hooks/reduxHooks'
import { closeEditModal } from '../../services/redux/slices/linkEditSlice'

import styles from './HomePage.module.css'
import LinkCreator from '../LinkCreator/LinkCreator'
import EditLink from '../EditLink/EditLink'

const HomePage: React.FC = () => {
  const [tab, setTab] = useState<'all' | 'favorite'>('all')
  const { isEditModalOpen } = useSelector(store => store.linkEdit)
  const { isOpen } = useSelector(store => store.linkInfo)
  const dispatch = useDispatch()

  const closeModal = (): void => {
    dispatch(closeEditModal())
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <LinkCreator />
        <LinkItems tab={tab} change={setTab}/>
        {isOpen
          ? <LinkInfo />
          : null
        }
        {isEditModalOpen
          ? <Modal title='Редактировать ссылку' close={closeModal}><EditLink /></Modal>
          : null
        }
      </div>
    </div>
  )
}

export default HomePage