import React from 'react'
import HomePage from '../components/HomePage/HomePage'

const Home: React.FC = () => {
  document.title = 'Скидки и Промокоды - Сокращение ссылок'
  return (
    <HomePage />
  )
}

export default Home 