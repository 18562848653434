import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import { Home, Login } from '../../pages'
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute'
import UnProtectedRoute from '../UnProtectedRoute/UnProtectedRoute'

import { useDispatch } from '../../services/hooks/reduxHooks'
import { checkAuth } from '../../services/api/api'

const App: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    checkAuth(dispatch)
  }, [dispatch])


  return (
    <Routes> 
      <Route element={<ProtectedRoute/>}>
        <Route path='/' element={<Home />} />
      </Route>
      <Route element={<UnProtectedRoute/>}>
        <Route path='/login' element={<Login />} />
      </Route>
    </Routes>
  );
}

export default App;
