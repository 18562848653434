import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from '../../services/hooks/reduxHooks'
import { IInput } from './ILinkCreator'
import { createLink } from '../../services/api/api'
import cryptoRandomString from 'crypto-random-string'
import ButtonLoader from '../ButtonLoader/ButtonLoader'

import styles from './LinkCreator.module.css'

const LinkCreator: React.FC = React.memo(() => {
  const { createLoading, createSuccess } = useSelector(state => state.links)
  const dispatch = useDispatch()
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const [inputDisabled, setInputDisabled] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<IInput>({
    name: '',
    link: '',
    url: '',
    favorite: false
  })
  const checkBoxTextCn: string = inputValue.favorite ? styles.checkboxTextActive : styles.checkboxText

  useEffect(() => {
    if (inputValue.link.length !== 0 && !createLoading) {
      setBtnDisabled(false)
    } else setBtnDisabled(true)
  }, [inputValue, createLoading])

  useEffect(() => {
    if (createLoading) {
      setInputDisabled(true)
    } else setInputDisabled(false)
  }, [createLoading])

  useEffect(() => {
    if (createSuccess) {
      setInputValue({
        name: '',
        link: '',
        url: '',
        favorite: false
      })
    }
  }, [createSuccess])

  const handleInputChange = (e: React.BaseSyntheticEvent): void => {
    const target = e.target
    const name = target.name
    const value = target.type === "checkbox" ? target.checked : target.value
    setInputValue({
      ...inputValue,
      [name]: value,
    })
  }
  const onCLickCheckboxHandler = (): void => {
    setInputValue({
      ...inputValue,
      favorite: !inputValue.favorite,
    })
  }
  const createLinkHendler = async (e: React.BaseSyntheticEvent): Promise<void> => {
    e.preventDefault()
    const sendingName = inputValue.name === '' ? inputValue.link : inputValue.name
    const sendingUrl = inputValue.url === '' ? cryptoRandomString({length: 5}) : inputValue.url
    await createLink(dispatch, {
      link: inputValue.link,
      name: sendingName,
      url: sendingUrl,
      favorite: inputValue.favorite
    })
  }

  return (
    <div className={styles.box}>
      <div className={styles.box__fixed}>
        <div className={styles.title}>Создать ссылку</div>
        <form className={styles.form} onSubmit={createLinkHendler}>
          <input placeholder='Ссылка'
            className={styles.input}
            name={'link'}
            value={inputValue.link}
            onChange={handleInputChange}
            disabled={inputDisabled}
          />
          <input placeholder='Название (Не обязательно)'
            className={styles.input}
            name={'name'}
            value={inputValue.name}
            onChange={handleInputChange}
            disabled={inputDisabled}
          />
          <input placeholder='Короткий URL (Не обязательно)'
            className={styles.input}
            name={'url'}  value={inputValue.url}
            onChange={handleInputChange}
            disabled={inputDisabled}
          />
          <label htmlFor="favorite" className={styles.checkbox} onClick={onCLickCheckboxHandler}>
            <input type="checkbox"
              name='favorite'
              checked={inputValue.favorite}
              onChange={handleInputChange}
              disabled={inputDisabled}
            />
            <p className={checkBoxTextCn}>Сохранить в избранное</p>
          </label>
          <button disabled={btnDisabled} className={styles.button}>
            {createLoading ?
              <ButtonLoader />
              :
              'Создать'
            }
          </button>
        </form>
      </div>
    </div>
  )
})

export default LinkCreator