import { configureStore } from "@reduxjs/toolkit"
import userReducer from './slices/userSlice'
import linksReducer from './slices/linksSlice'
import linkInfoReducer from "./slices/linkInfoSlice"
import linkEditReducer from './slices/linkEditSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    links: linksReducer,
    linkInfo: linkInfoReducer,
    linkEdit: linkEditReducer
  },
  devTools: false
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch