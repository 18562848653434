import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import { IoClose } from "react-icons/io5";

import styles from './Modal.module.css'

const modalRoot = document.getElementById("modalRoot") as HTMLElement

interface IModal {
  children: React.ReactNode,
  title: string,
  close (): void
}

type KeyboardEvent = {
  key: string;
}

const Modal: React.FC<IModal> = (props) => {

  useEffect(() => {
    const keydownCloseModal = (e: KeyboardEvent): void  => {
      if(e.key === "Escape") props.close()
    }
    document.addEventListener('keydown', keydownCloseModal)
    return () => document.removeEventListener('keydown', keydownCloseModal)
  }, []) // eslint-disable-line


  return ReactDOM.createPortal(
    <div className={styles.box}>
      <div className={styles.modal}>
        <div className={styles.heading}>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.close} onClick={props.close}><IoClose /></div>
        </div>
        {props.children}
      </div>
      <div className={styles.overlay} onClick={props.close}/>
    </div>
  , modalRoot)
}

export default Modal