import React from 'react'
import { useSelector } from '../../services/hooks/reduxHooks'
import { Navigate, Outlet } from 'react-router-dom'
import Loader from '../Loader/Loader'

const UnProtectedRoute: React.FC = () => {
  const user = useSelector((state) => state.user.name)
  const { authChecked } = useSelector((state) => state.user)

  if (!authChecked) return <div className='loader__box'><Loader /></div>
  if (user) return <Navigate to="/" />

  return (
    <Outlet />
  )
}

export default UnProtectedRoute