import React from 'react'

import styles from './Tab.module.css'

interface ITab {
  active: 'all' | 'favorite',
  change: any
}

const Tab = React.memo((props: ITab) => {
  const setActiveAll = (): void => {
    props.change('all')
  }

  const setActiveFavorite = (): void => {
    props.change('favorite')
  }

  return (
    <div className={styles.box}>
      <div
        onClick={setActiveAll}
        className={`${styles.item} + ${props.active === 'all' ? styles.active : ''}`}
      >
        Все ссылки
      </div>
      <div
        onClick={setActiveFavorite}
        className={`${styles.item} + ${props.active === 'favorite' ? styles.active : ''}`}
      >
        Избранное
      </div>
    </div>
  )
})

export default Tab