import React, { useState } from 'react'
import { useSelector, useDispatch } from '../../services/hooks/reduxHooks'
import { fetchEditLink } from '../../services/api/api'

import styles from './EditLink.module.css'
import ButtonLoader from '../ButtonLoader/ButtonLoader'

const EditLink = () => {
  const dispatch = useDispatch()
  const { link } = useSelector(store => store.linkInfo)
  const { loading } = useSelector(store => store.linkEdit)
  const linkName: any = link?.name
  const linkTo: any = link?.to
  const linkFrom: any = link?.from
  const id: any = link?.id

  const [ editLink, setEditLink ] = useState({
    name: linkName,
    to: linkTo,
    from: linkFrom
  })

  const handleInputChange = (e: React.BaseSyntheticEvent): void => {
    const target = e.target
    const name = target.name
    setEditLink({
      ...editLink,
      [name]: target.value,
    })
  }

  const editLinkHandler = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    fetchEditLink(dispatch, id, editLink)
  }

  return (
    <form className={styles.form} onSubmit={editLinkHandler}>
      <label>
        <p className={styles.lable}>Название</p>
        <input placeholder='Название' value={editLink.name} name='name' onChange={handleInputChange} className={styles.input} />
      </label>
      <label>
        <p className={styles.lable}>Оригинальная ссылка</p>
        <input placeholder='Оригинальная ссылка' value={editLink.to} name='to' onChange={handleInputChange} className={styles.input} />
      </label>
      <label>
        <p className={styles.lable}>Короткий URL</p>
        <input placeholder='Короткий URL' value={editLink.from} name='from' onChange={handleInputChange} className={styles.input} />
      </label>
      <div className={styles.bottom}>
        <button className={styles.btn}>
          {loading 
            ? <ButtonLoader />
            : 'Сохранить'}
        </button>
      </div>
    </form>
  )
}

export default EditLink