import React from 'react'
import LoginPage from '../components/LoginPage/LoginPage'

const Login: React.FC = () => {
  document.title = 'Скидки и Промокоды - Авторизация'
  return (
    <LoginPage />
  )
}

export default Login